<template>
  <v-app class="app-wrapper">
    <v-app-bar app v-if="isLoggedIn">
      <div class="d-flex align-center">
        <a href="/">
          <v-img
            alt="Panasonic Logo"
            class="nav-logo"
            contain
            src="./assets/logo_panasonic.svg"
          />
        </a>
      </div>
      <div class="app-header-spacing"></div>
      <template>
        <div class="menu-wrapper-mobile">
          <v-btn
            type="button"
            class="burger-button"
            @click.prevent="toggle"
            title="Menu"
          ></v-btn>
        </div>
        <div class="burger" :class="{ active: isBurgerActive }">
          <ul>
            <li v-if="accessibleRoutes.dashboard">
              <a href="/"> Home </a>
            </li>
            <li v-if="accessibleRoutes.der">
              <a href="/battery-plant"> DER </a>
            </li>
            <li v-if="accessibleRoutes.schedule">
              <a href="/schedule"> Schedule </a>
            </li>
            <li v-if="accessibleRoutes.alarm">
              <a href="/alarms"> Alarm </a>
            </li>
            <li v-if="accessibleRoutes.report">
              <a href="/alarms"> Report </a>
            </li>
          </ul>
        </div>
        <div class="menu-wrapper">
          <v-row no-gutters>
            <v-spacer />
            <v-col cols="2" md="2" sm="2" v-if="accessibleRoutes.dashboard">
              <div class="pd-left">
                <a href="/">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-home"
                    @click="active = 'home'"
                    :class="{ active: getActive() === 'home' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2" v-if="accessibleRoutes.der">
              <div class="pd-left-der">
                <a href="/battery-plant">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-der"
                    @click="active = 'der'"
                    :class="{ active: getActive() === 'der' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2" v-if="accessibleRoutes.schedule">
              <div class="pd-left">
                <a href="/schedule">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-schedule"
                    @click="active = 'schedule'"
                    :class="{ active: getActive() === 'schedule' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2" v-if="accessibleRoutes.alarm">
              <div class="pd-left-alarm">
                <a href="/alarms">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-alarm"
                    @click="active = 'alarm'"
                    :class="{ active: getActive() === 'alarm' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2" v-if="accessibleRoutes.report">
              <div class="pd-left-report">
                <a href="/reports">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-report"
                    @click="active = 'report'"
                    :class="{ active: getActive() === 'report' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
            <v-col cols="2" md="2" sm="2">
              <div class="pd-left-user">
                <a href="/users/profile">
                  <v-btn
                    :ripple="false"
                    icon
                    class="menu-icon menu-icon-user"
                    @click="active = 'user'"
                    :class="{ active: getActive() === 'user' }"
                  >
                  </v-btn>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="logout-panel">
          <v-row no-gutters>
            <v-col cols="7" md="7" sm="6">
              <div class="date-info">
                <div class="time">{{ nowTime }}</div>
                <div class="date">{{ nowDate }}</div>
              </div>
            </v-col>
            <v-col cols="5" md="5" sm="6">
              <div class="logout-button">
                <v-btn :ripple="false" @click="logout">
                  <!-- <v-icon>mdi-power-standby</v-icon> -->
                  <span>Logout</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view />
        <div class="clearfix"></div>
        <template>
          <v-row no-gutters>
            <v-col cols="12" lg="3" md="4" sm="12">
              <div class="der-footer">DER Aggregator platform Ver.0.3.5 ©2021</div>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<style src="./assets/css/styles.css"></style>

<script>
import { auth } from "./services";
export default {
  name: "App",
  data() {
    return {
      active: "home",
      nowTime: "",
      nowDate: "",
      isBurgerActive: false,
    };
  },
  methods: {
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    logout() {
      auth.logout().finally(() => {
        this.$store.dispatch("logout").then(() => location.reload());
        // .then(() => this.$router.push("/login").catch(() => {}));
      });
    },
    timeFormate(timeStamp) {
      const now = new Date(timeStamp);
      let year = now.getFullYear();
      let month = now.toLocaleString("default", { month: "long" });
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      let hh = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
      let mm = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
      let ss = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
      this.nowDate = month + " " + date + ", " + year;
      this.nowTime = hh + ":" + mm + ":" + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
    },
    getActive() {
      switch (window.location.pathname) {
        case "/":
          return "home";
        case "/users/profile":
        case "/users/list":
        case "/users/groups":
          return "user";
        case "/reports":
          return "report";
        case "/alarms":
          return "alarm";
        case "/battery-plant":
          return "der";
        case "/schedule":
          return "schedule";
        default:
          return "home";
      }
    },
  },
  mounted() {
    setInterval(this.nowTimes, 1000);
    // this.nowTimes();
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
  },
};
</script>
