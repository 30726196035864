import HTTP_SERVICE from './http-service';

export default {

    getEnergyManagementUnit(site_id){
        return HTTP_SERVICE.get(`components?component=energy_management_unit&site_id=${site_id}`);
    },

    getPowerConditioningSystem(site_id){
        return HTTP_SERVICE.get(`components?component=power_conditioning_system&site_id=${site_id}`);
    },

    getBatteryManagementSystem(site_id){
        return HTTP_SERVICE.get(`components?component=battery_management_system&site_id=${site_id}`);
    },
    
    getPowerMetric(site_id){
        return HTTP_SERVICE.get(`components?component=power_metrics&site_id=${site_id}`);
    },

    getWasteToEnergy(site_id, month){
        return HTTP_SERVICE.get(`components?component=waste_to_energy&site_id=${site_id}&by=${month}`);
    },

    getVehicleToBuilding(site_id, month){
        return HTTP_SERVICE.get(`components?component=vehicle_to_building&site_id=${site_id}&by=${month}`);
    },
}