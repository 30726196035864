export const accessibleMenus = {
    dashboard: 'Dashboard',
    der: 'Der',
    schedule: 'Schedule',
    alarm: 'Alarms',
    user: 'Users',
    report: 'Report'
}

export const allowPermissions = {
    create: 'Add',
    edit: 'Edit',
    delete: 'Delete'
}

export const alarmStatus = [
    {
        name: 'Active',
        value: 'active'
    },
    {
        name: 'Acknowledged',
        value: 'acknowledged'
    },
    {
        name: 'Resolved',
        value: 'resolved'
    }
]

export const reportTypes = [
    {
        name: 'Revenue',
        value: 'revenue'
    },
    {
        name: 'Energy & Power Summary',
        value: 'summary'
    },
    {
        name: 'Energy & Power Detail',
        value: 'detail'
    },
    {
        name: 'Application Schedule Reports',
        value: 'schedule'
    }
]

export const noAuthRoutes = [
    'Login',
    'Change Password',
    'Forgot Password',
    '404'
]

export const allowRoutes = {
    ['Dashboard']: 'dashboard',
    ['Users']: 'user',
    ['Report Management']: 'report',
    ['Alarm Management']: 'alarm',
    ['Schedule Mangement']: 'schedule',
    ['Battery Plant Mangement']: 'der',
    ['User Management']: 'users',
    ['Group Mangement']: 'userGroups'
}

export const footerProps = {
    disableItemsPerPage: true,
    itemsPerPageText: 'Showing',
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-menu-left',
    nextIcon: 'mdi-menu-right',
    pageText: ''
  }

export const monthYearItems = [
    {
        name: 'By Month',
        value: 'month'
    },
    {
        name: 'By Year',
        value: 'year'
    }
]

export const mapStyles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];