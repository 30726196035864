import auth from "./auth-service";
import userProfileService from "./user-profile-service";
import groupService from "./group-service";
import userService from "./user-service";
import alarmService from "./alarm-service";
import reportService from "./report-service";
import validationService from "./validation-service";
import dashboardService from "./dashboard-service";
import batteryService from "./battery-service";

const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone
const getTimeZoneName = () => {
  const name = new Date().toString().match(/\((\w|\W+\s?)*\)/);
  return name?.length ? name[0] : `(${Intl.DateTimeFormat().resolvedOptions().timeZone})`;
}
const paddingZero = (num) => {
  if (num < 10) {
    return String(num).padStart(2, "0");
  }
  return num;
}
const getTimeZoneOffset = () => {
  const offset = 0 - new Date().getTimezoneOffset();
  const hours = offset / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  return `${rhours > 0 ? '+' : ''}${rhours}:${paddingZero(minutes)}`;
}
const getOS = () => {
  let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}
const getImageUrl = (url) => {
  return `${process.env.VUE_APP_API}/${url}`;
}
const formatPhoneNum = (str) => {
  if (!str) return '';
  return str.replace(/(\d{3})(\d{3})(\d*)/, "$1-$2-$3");
}

export {
    auth,
    userProfileService,
    groupService,
    userService,
    alarmService,
    reportService,
    validationService,
    dashboardService,
    formatPhoneNum,
    getTimeZone,
    getOS,
    getImageUrl,
    batteryService,
    getTimeZoneName,
    getTimeZoneOffset
}