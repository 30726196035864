import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import { noAuthRoutes , allowRoutes } from '../contants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/change-password/:token',
    name: 'Change Password',
    component: () => import(/* webpackChunkName: "change-password" */ '../views/ChangePassword.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/users/Users.vue'),
    children: [
      {
        path: 'groups',
        name: 'Group Mangement',
        component: () => import(/* webpackChunkName: "users-group" */ '../views/users/GroupManagement.vue')
      },
      {
        path: 'list',
        name: 'User Management',
        component: () => import(/* webpackChunkName: "users-list" */ '../views/users/UserManagement.vue')
      },
      {
        path: 'profile',
        name: 'User Profile',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/UserProfile.vue')
      }
    ]
  },
  {
    path: '/reports',
    name: 'Report Management',
    component: () => import(/* webpackChunkName: "reports" */ '../views/reports/ReportManagement.vue')
  },
  {
    path: '/alarms',
    name: 'Alarm Management',
    component: () => import(/* webpackChunkName: "alarms" */ '../views/alarms/AlarmManagement.vue')
  },
  {
    path: '/schedule',
    name: 'Schedule Mangement',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/schedule/ScheduleManagement.vue')
  },
  {
    path: '/battery-plant',
    name: 'Battery Plant Mangement',
    component: () => import(/* webpackChunkName: "battery" */ '../views/battery-plants/BatteryPlantMangement.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && !store.getters.accessibleRoutes[allowRoutes[to.name]] && to.name != 'User Profile') {
    next({name: 'User Profile'})
  }else if (noAuthRoutes.findIndex(el => el == to.name) < 0 && !store.getters.isLoggedIn) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
