import HTTP_SERVICE from './http-service';

export default {
    getGroupList() {
        return HTTP_SERVICE.get('/user-groups');
    },
    getUserGroup(id) {
        return HTTP_SERVICE.get(`/user-groups/${id}`);
    },
    storeUserGroup(data) {
        return HTTP_SERVICE.post('/user-groups', data);
    },
    updateUserGroup(id, data) {
        return HTTP_SERVICE.put(`/user-groups/${id}`, data);
    },
    deleteUserGroup(id) {
        return HTTP_SERVICE.delete(`/user-groups/${id}`);
    }
}