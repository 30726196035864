import HTTP_SERVICE from './http-service';

export default {
    getEnergyGenerated(site_id) {
        return HTTP_SERVICE.get(`components?component=energy_generated&site_id=${site_id}`);
    },
    getTodayEnergySummary(site_id) {
        return HTTP_SERVICE.get(`components?component=today_energy_summary&site_id=${site_id}`);
    },
    getEnergyConsumption(by, site_id) {
        return HTTP_SERVICE.get(`components?component=energy_consumption&by=${by}&site_id=${site_id}`);
    },
    getRevenueAndCost(site_id) {
        return HTTP_SERVICE.get(`components?component=monthly_revenue_vs_operation_cost&site_id=${site_id}`);
    },
    getMonthlyDemandCharges(site_id) {
        return HTTP_SERVICE.get(`components?component=monthly_demand_charges&site_id=${site_id}`);
    },
    getRealTimeCharts(site_id, by, timezone) {
        return HTTP_SERVICE.get(`components?component=realtime_charts&site_id=${site_id}&by=${by}&timezone=${encodeURIComponent(timezone)}`);
    },
    getSiteSummary(site_id) {
        return HTTP_SERVICE.get(`components?component=site_summary&site_id=${site_id}`);
    }
}