import HTTP_SERVICE from './http-service';

export default {
    getListAlarms(param) {
        let url = '/alarms';
        if (param) {
            url+='?search=';
            if (param.search) {
                url+=`${param.search}`
            }
            if (param.sortBy) {
                url+=`&sortColumn=${param.sortBy}`
            }
            if (param.sortType) {
                url+=`&sortBy=${param.sortType}`
            }
            if(param.pageNo >= 0) {
                url+=`&offset=${param.pageNo}`
            }
            if(param.pageSize) {
                url+=`&limit=${param.pageSize}`
            }
        }
        return HTTP_SERVICE.get(url);
    },
    updateAlarm(id, data) {
        return HTTP_SERVICE.put(`/alarms/${id}`, data);
    }
}