import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 5000,
    headers: { 'X-Api-Key': process.env.VUE_APP_X_API_KEY }
});
if (localStorage.getItem('token')) {
    instance.defaults.headers.common['Access-Token'] = `Bearer ${localStorage.getItem('token')}`;
}

export default {
    instance,
    get(url) {
        return instance.get(url);
    },
    post(url, params) {
        return instance.post(url, params);
    },
    put(url, params) {
        return instance.put(url, params);
    },
    delete(url) {
        return instance.delete(url);
    },
    downloadCSV(url){
        return axios.request({url, method: 'GET', responseType: 'blob', baseURL: process.env.VUE_APP_API,
        timeout: 5000,
        headers: { 'X-Api-Key': process.env.VUE_APP_X_API_KEY }})
    }
}