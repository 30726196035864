import HTTP_SERVICE from './http-service';

export default {
    getCurrentUserProfile() {
        return HTTP_SERVICE.get('users/profile');
    },
    setUserProfile(data) {
        return HTTP_SERVICE.put('users/profile', data);
    },
    updateUserPassword(data) {
        return HTTP_SERVICE.put('users/profile/update-password', data);
    }
}