import HTTP_SERVICE from './http-service';

export default {
    getListReports(param) {
        let url = '/reports';
        if (param) {
            url+='?search=';
            if (param.search) {
                url+=`${param.search}`
            }
            if (param.sortBy) {
                url+=`&sortColumn=${param.sortBy}`
            }
            if (param.sortType) {
                url+=`&sortBy=${param.sortType}`
            }
            if(param.pageNo >= 0) {
                url+=`&offset=${param.pageNo}`
            }
            if(param.pageSize) {
                url+=`&limit=${param.pageSize}`
            }
        }
        return HTTP_SERVICE.get(url);
    },
    createReport(data) {
        return HTTP_SERVICE.post('/reports', data);
    },
    downloadCSV(url) {
        return HTTP_SERVICE.downloadCSV(url);
    },
    deleteReport(id) {
        return HTTP_SERVICE.delete(`/reports/${id}`);
    }
}